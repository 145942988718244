.closeIcon {
    color: var(--primary);
    font-size: 28px;
    cursor: pointer;
    transition: 0.2s;
}

.closeIcon:hover {
    color: var(--primary-hover);
    /* transform: scale(1.1);
    transform-origin: center; */
}

.infoCircle {
    font-size: 14px;
    cursor: pointer;
}

.toggleIcon {
    font-size: 22px;
    cursor: pointer;
    transition: 0.2s;
}

.filterIcon {
    margin-right: 3px;
    font-size: 14px;
}