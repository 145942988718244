.textArea {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 15px;
  transition: 0.2s;
  font-size: 15px;
  color: var(--primary-darker);
  background-color: var(--search-background);
  resize: none;
  font-family: "Roboto", sans-serif;
}
.textArea:focus, .textArea:active {
  border: 1px solid var(--primary-hover) !important;
  outline: none;
}
.textArea::placeholder {
  color: var(--primary)

}
.textArea:hover {
  outline: none;
  border: 1px solid transparent;
}

.label {
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  color: var(--primary);
}
