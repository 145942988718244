.oneImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: var(--box-shadow-light);
}
.grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.firstContainer {
  grid-column: 1 / 14;
  grid-row: 1 / 14;
}
.secondContainer {
  grid-column: 2 / 15;
  grid-row: 2 / 15;
}

.firstContainerThree {
  grid-column: 1 / 14;
  grid-row: 1 / 14;
}
.secondContainerThree {
  grid-column: 2 / 15;
  grid-row: 2 / 15;
}
.thirdContainerThree {
  grid-column: 3 / 16;
  grid-row: 3 / 16;
}
