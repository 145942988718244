.grid {
  margin: 10px 5px;
  display: grid;
  grid-template-columns: 90px 1fr 50px;
  grid-gap: 15px;
}
@media only screen and (min-width: 950px) {
.grid {
  margin: 20px;
  display: grid;
  grid-template-columns: 120px 1fr 70px;
  grid-gap: 20px;
}
.name {
    font-size: 22px !important;
}
}

.image {
  width: 100%;
  height: auto;
}

.name {
  font-size: 18px;
  color: var(--primary-darker);
  transition: 0.2s;
  width: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.name:hover {
  color: var(--primary-dark);
}
.qty {
    color: var(--primary);
    font-size: 18px;
    margin-top: 20px;
}

.price {
    color: var(--primary-dark);
    font-size: 18px;
    text-align: right;
}