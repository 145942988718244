.input {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 1.25rem;
  transition: 0.2s;
  font-size: 16px;
  box-sizing: border-box;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  box-shadow: var(--input-box-shadow);
  margin-top: 3px;
  color: #333333;
}


.inputError, .inputError:hover {
  transition: 0.2s;
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--delete-color) !important;
}
.input:focus,
.input:active, .inputSelect:focus, .inputSelect:active {
  outline: 0;
  border-color: hsla(201, 96%, 45%, 50%);
  box-shadow: 0px 1px 1px rgba(0,0,0,0.03), 0px 3px 6px rgba(0,0,0,0.02), var(--input-focus-box-shadow);
}
.input::placeholder {
  color: #888888;
}

.inputSelect:invalid {
  color: #888888 !important;
}

.inputSelect {
  width: 100%;
  border-radius: 5px;
  padding: 1.2rem;
  color: #333333;
  transition: 0.2s;
  font-size: 16px;
  box-sizing: border-box;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  box-shadow: var(--input-box-shadow);
  margin-top: 3px;
}
.inputSelectNormal {
  border: 1px solid #e6e6e6;
}
.inputSelect:disabled {
  color: var(--muted-text-color) !important;
  background-color: var(--primary-muted);
}

.label {
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  color: #535353;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
