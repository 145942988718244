.rowFlex {
  display: flex;
}
.inputContainer {
  width: 100%;
  padding: 5px 0;
}
.inputPadding {
  margin: 5px 0;
}
.inputPaddingNoLabel {
  margin: 10px 0;
}

@media only screen and (min-width: 500px) {
  .inputContainer {
    max-width: 100%;
    width: 50%;
    padding: 5px 0;
  }
  .inputContainer:first-child {
    padding-right: 5px;
  }

  .inputContainer:last-child {
    padding-left: 5px;
  }
  .addressGrid {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .nameGrid {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}
.grid {
  margin: 25px 0;
}
@media only screen and (min-width: 950px) {
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
  }
  .orderSummaryContainer {
    grid-column: 2;
    grid-row: 1;
  }
  .mainContainer {
    grid-column: 1;
    grid-row: 1;
  }
}

.addressGrid,
.nameGrid {
  display: block;
}

.subheading {
  font-size: 24px;
  color: var(--primary-dark);
  font-weight: 600;
}
.showSecondAddress {
  font-size: 14px;
  color: var(--primary);
  margin: 3px 0;
  cursor: pointer;
  transition: 0.2s;
}

.showSecondAddress:hover {
  color: var(--primary-hover);
}
.addressContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.addressContainer:hover .removeIcon {
  visibility: visible;
}
.addressContainer .removeIcon {
  visibility: hidden;
  color: var(--primary);
  font-size: 24px;
  cursor: pointer;
  transition: 0.2s;
}

.removeIcon:hover {
  color: var(--primary-hover);
}
.errorContainer {
  height: 25px;
  width: 100%;
  text-align: center;
  color: var(--delete-color);
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.columnRight {
  text-align: right;
}

.stepsText {
  color: var(--muted-text-color);
  margin-top: 5px;
  font-size: 14px;
}

.editLink {
  color: var(--primary);
  transition: 0.2s;
  font-size: 16px;
  cursor: pointer;
}
.editLink:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}