.title {
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-dark);
}
.row {
    display: flex;
    flex-wrap: wrap;
}
.inputContainer {
    margin-bottom: 10px;
}
.tab {
    margin: 5px 7px;
    background-color: var(--unselected-tab);
    color: var(--button-text-color);
    padding: 7px 15px;
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;
}

.tab:hover {
    background-color: var(--primary-hover);
}

.tab:first-child {
    margin-left: 0;
}
.tab:last-child {
    margin-right: 0;
}

.selected, .selected:hover {
    background-color: var(--primary);
}

.ordersContainer {
    height: 50vh;
    overflow: auto;
    overflow-x: hidden;
}