.buttonContainerLeft {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
  padding: 15px;
}
.buttonContainerRight {
  right: 0;
  position: absolute;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
  padding: 15px;
}

.container {
  height: 600px;
  max-height: 60vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}

button {
  background-color: transparent;
  border: none;
}

.buttonContainerLeft:hover .arrow,
.buttonContainerRight:hover .arrow {
  opacity: 1;
}

.arrow {
  transition: 0.2s;
  font-size: 34px;
  color: rgba(255, 255, 255, 0.4);
  opacity: 0;
  cursor: pointer;
}

.arrow:hover {
  transform: scale(1.15);
  color: rgba(255, 255, 255, 0.6);
}
