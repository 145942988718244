.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editIcon {
  color: var(--primary);
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s;
}
.editIcon:hover {
    color: var(--primary-hover);
}


.contentRow {
    display: flex;
    margin-top: 10px;
}

.description {
    font-weight: 600;
    color: var(--primary);
    font-size: 18px;
}
.descriptionValue {
    font-weight: 700;
    color: var(--primary-dark);
    font-size: 18px;
    margin-left: 5px;
}