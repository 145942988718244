.content {
  grid-gap: 16px;
  margin: 25px 0;
}


.contentModal {
  grid-gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.column {
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 0.5rem;
}
.imageModal {
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: contain;
}
.productInfoContainer {
  margin-top: 20px;
}

.productName {
  font-size: 30px;
  font-weight: 500;
  color: var(--primary-dark);
  margin-bottom: 10px;
}
.productDescription,
.productDescriptionModal {
  font-size: 19px;
  font-weight: 500;
  color: var(--muted-text-color);
  white-space: pre-wrap;
}

.productSize {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--muted-text-color);
}
.productDescriptionModal {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.productPrice {
  font-size: 26px;
  margin-top: 10px;
  color: var(--primary-darker);
}
.rowSpaced {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.row {
  display: flex;
}
.subheading {
  font-size: 20px;
  color: var(--primary-dark);
}

.inStock {
  font-weight: 700;
  color: var(--success-color-hover);
  font-size: 18px;
}
.noStock {
  font-weight: 700;
  color: var(--delete-color);
  font-size: 18px;
}

.padding {
  padding: 10px 0;
}

.select {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 0.1rem var(--primary-dark) solid;
  color: var(--primary-dark);
  font-size: 1.6rem;
  background-color: var(--search-background);
  text-overflow: ellipsis;
}
.selectSmall {
  padding: 0.3rem 0.6rem;
  border-radius: 0.5rem;
  border: 0.1rem var(--primary-dark) solid;
  color: var(--primary-dark);
  font-size: 1.4rem;
  background-color: var(--search-background);
}

.backgroundContainer {
  padding: 15px;
  /* background-color: var(--search-background); */
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  margin: 20px 0;
}
.backgroundContainerModal {
  padding: 15px;
  background-color: var(--search-background);
  border-radius: 10px;
}

.shareContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.shareContainerModal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.addToCartContainerModal {
  margin: 20px 0;
}
.linkContainer {
  padding: 0 10px;
}

.reviewsIcon {
  color: var(--primary);
  font-size: 28px;
  animation: rotate-up 1s linear;
}

.reviewsContainer {
  padding-top: 15px;
}

.reviewItem {
  padding: 20px 10px;
}

.noData {
  color: var(--primary-hover);
  display: flex;
  justify-content: center;
}

.reviewInfo {
  padding-left: 5px;
  color: var(--primary);
}

.reviewComment {
  font-size: 18px;
  color: var(--primary-dark);
  padding-top: 5px;
}

.productInfoContainerModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.relatedProducts {
  display: none;
}

.seeMore {
  margin-top: 3px;
  color: var(--muted-text-color);
  transition: 0.2s;
}

.seeMore:hover {
color: var(--muted-text-color-hover)}

.relatedProductsMobile {
  display: inline;
}

.moreFrom {
  color: var(--primary);
  font-weight: 600;
  transition: 0.2s;
  margin-bottom: 5px;
}
.moreFrom:hover {
  color: var(--primary-hover);
  cursor: pointer;
}

.brandLogo {
  width: auto;
  max-width: 90%;
  max-height: 200px;
  height: auto;
  object-fit: contain;
}

.brandContainer {
  display: flex;
  width: 100%;
  margin: 10px 0 20px;
  justify-content: center;
}

.brandName {
  font-size: 25px;
  font-weight: 700;
  color: var(--primary-dark);
  margin-top: 15px;
}
.results {
  color: var(--primary);
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 16px;
}

@media only screen and (min-width: 765px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1;
  }
}

@media only screen and (min-width: 980px) {
  .productInfoContainer {
    display: grid;
    grid-template-columns: 1fr 120px;
    grid-gap: 30px;
  }
  .relatedProducts {
    display: inline;
  }
  .relatedProductsMobile {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .contentModal {
    display: block;
  }
  .productDescriptionModal {
    margin-top: 15px;
  }
  .shareContainerModal {
    display: none;
  }
}
