.input {
  width: 100%;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0 15px;
  transition: 0.2s;
  font-size: 15px;
  color: var(--primary-darker);
  background-color: var(--search-background);
  box-sizing: border-box;
}
.inputPassword {
  position: relative;
}

.inputOutline, .inputOutline:hover {
  border: 1px solid var(--primary) !important;
}
.inputError, .inputError:hover {
  border: 1px solid var(--delete-color) !important;
  background-color: var(--delete-background);
}
.input:focus,
.input:active{
  border: 1px solid var(--primary-hover) !important;
  outline: none;
}
.input::placeholder {
  color: var(--primary);
}
.input:hover {
  outline: none;
  border: 1px solid transparent;
}
.inputSelect {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 14px;
  transition: 0.2s;
  color: var(--primary-darker);
  font-size: 16px;
}
.inputSelectNormal {
  border: 1px solid var(--primary) !important;
  background-color: var(--search-background);
}
.inputSelect:disabled {
  color: var(--muted-text-color) !important;
  background-color: var(--primary-muted);
}
.inputSelectNoBorder {
  border: none !important;
}


.label {
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  color: var(--primary);
  user-select: none;
}

.infoLabel {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-hover);
  user-select: none;
}

.eyeContainer {
  position: absolute;
  right: 7px;
  top: 12px;
  width: 30px;
  display: flex;
  justify-content: center;

}

.eye {
  color: var(--primary);
  cursor: pointer;
  transition: 0.2s;
}

.eye:hover {
  color: var(--primary-hover);
}