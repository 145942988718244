.container,
.skeletonContainer {
  width: 100%;
  flex: 1;
  border: 1px solid var(--card-outline-color);
  border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
}

.container:active {
  /* transform: scale(1.02); */
  box-shadow: var(--box-shadow);
  transform-origin: center;
  border: 1px solid var(--card-outline-color-hover);
}

@media only screen and (min-width: 767px) {
  .container:hover {
    /* transform: scale(1.02); */
    box-shadow: var(--box-shadow);
    transform-origin: center;
    border: 1px solid var(--card-outline-color-hover);
  }
}
.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  transition: 0.2s;
}
.imageContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /*   display: flex;
  align-items: center; */
  line-height: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageSkeleton {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /*   display: flex;
  align-items: center; */
  line-height: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: 0.2s;
  background-color: var(--background-hover-color);
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--background-hover-color);
}
.imageContainer:before {
  content: "";
  display: block;
  padding-top: 100%;
}

@media screen and (max-width: 700px) {
  .imageOverlay, .imageOverlay:hover {
    display: none !important;
  }
  .imageContainer:hover .imageOverlay {
    animation: none;
    display: none;
  }
  .imageContainer:hover .image {
    filter: none !important;
  }
}
.imageOverlay {
  position: absolute;
  margin: 0 auto;
  display: none;
  padding: 10px 15px;
  background-color: var(--primary);
  color: var(--button-text-color);
  border-radius: 5px;
  font-weight: 600;
  transition: 0.2s;
}
.imageOverlay:hover {
  background-color: var(--primary-hover);
}

.imageContainer:hover .image {
  filter: blur(4px);
}

.imageOverlay:active .container {
  pointer-events: none;
}

.imageContainer:hover .imageOverlay {
  animation: fadeIn 0.5s forwards;
  display: block;
}

.cardTextContainer {
  height: 110px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.cardTextContainerNoReviews {
  height: 110px;
  padding: 10px;
  display: flex;
  flex-direction: column;
    box-shadow: inset 0 20px 10px -20px rgba(0, 0, 0, 0.1);
  background-color: var(--card-secondary-color);
}
.currency {
  font-weight: 500;
}
.priceContainer {
  font-size: 17px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  color: var(--muted-text-dark)
}
.priceContainerSkeleton {
  width: 60px;
  height: 22px;
  background-color: var(--background-hover-color);
  border-radius: 8px;
}
.productName {
  width: auto;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
  font-size: 18px;
  color: var(--main-text-color);
  padding-bottom: 3px;
}

.productNameSkeleton {
  height: 22px;
  margin: 2px 0;
  width: 90%;
  border-radius: 8px;
  background-color: var(--background-hover-color);
}
.productDescriptionSkeleton {
  height: 17px;
  width: 80%;
  border-radius: 8px;
  margin: 3px 0;
  background-color: var(--background-hover-color);
}
.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}
.shineLarge {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 804px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.productDescription {
  width: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  color: var(--muted-text-color);
  padding-bottom: 3px;
}
.reviewsContainer {
  background-color: var(--card-secondary-color);
  height: 45px;
  width: 100%;
  padding: 10px;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  box-shadow: inset 0 20px 10px -20px rgba(0, 0, 0, 0.1);
}
.reviewsContainerSkeleton {
  height: 22px;
  width: 50%;
  border-radius: 8px;
  background-color: var(--background-hover-color);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
