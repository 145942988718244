.cardContainer {
  border: 1px solid var(--card-outline-color);
  border-radius: 10px;
  border: 1px solid var(--card-outline-color);
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  height: 250px;
  position: relative;
  overflow: hidden;
}
.cardContainer:hover {
  border: 1px solid var(--card-outline-color-hover);
  box-shadow: var(--box-shadow);
}

.cardContainer:hover .image {
  filter: blur(3px);
}

.image {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
}

.textContainer {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0.822), rgba(255, 255, 255, 0));
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.title {
    font-size: 24px;
    padding: 15px;
    font-weight: 600;
    color: var(--primary-dark);
}