.readText {
    color: var(--primary-dark);
    font-size: 20px;
    font-weight: 600;
}

.center {
    display: flex;
    justify-content: center;
}

.row {
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    width: 100%;
}

.infoItem {
    margin-bottom: 10px;
    color: var(--primary-dark);
    font-size: 18px;
}