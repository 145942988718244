.key {
  color: var(--primary-dark);
  font-size: 18px;
  width: 60px;
}

.value {
  color: var(--primary-dark);
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
}

.row {
  display: flex;
  align-items: center;
}
