.container {
    display: flex;
    padding: 10px;
    
}

.star {
    font-size: 30px;
    cursor: pointer;
    color: var(--primary-hover);
    transition: 0.2s;
}

