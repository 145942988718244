.imageContainer {
    width: 100%;
    height: 380px;
    position: relative !important;
    margin: 10px 0;
}

.controls {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.slider {
  padding: 22px 0px;
}

.zoomRange {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 4px;
  border-radius: 10px;
  background-color: var(--primary-hover) !important;
  width: 60%;
  margin: 20px 0;
  cursor: pointer;

}

.zoomRange::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--primary);
  background: var(--primary) !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoomRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--primary);
  background: var(--primary);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}