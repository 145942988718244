.mainBackground {
  background-color: var(--secondary-bg-color);
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-dark);
}
.titleSmall {
  font-size: 26px;
  font-weight: 600;
  color: var(--primary-dark);
}
.orderNumber {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-dark);
}

.subheading {
  font-size: 17px;
  color: var(--primary);
}

.itemsContainer {
  margin: 20px 0;
}

.sectionContainer {
  margin-top: 30px;
}

.sectionTitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--primary-dark);
}

.sectionRow {
  display: flex;
  margin: 5px 0;
  align-items: center;
}
.icon,
.iconUser {
  color: var(--primary);
}
.iconUser {
  margin-right: -1px;
}
.iconContainer {
  width: 20px;
  display: flex;
  justify-content: center;
}

.text {
  padding-left: 5px;
  color: var(--primary-dark);
  font-size: 18px;
}

.mapsContainer {
  width: 100%;
  height: 200px;
  margin-top: 10px
}

.shippingRow {
  display: flex;
  margin: 3px;
  color: var(--primary-dark);
  font-size: 18px;
}

.orderInfo {
    margin: 10px 5px;
}

@media only screen and (min-width: 950px) {
.orderInfo {
    margin: 20px;
    margin-bottom: 15px;
}
}
.subtotal {
    font-size: 18px;
    color: var(--primary)
}
.total {
    font-size: 20px;
    color: var(--primary-dark);
    font-weight: 600;
}

.infoCircle {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
}

.rowPayment {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}
