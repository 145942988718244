:root {
  --dropdown-height: 200px;
}

.container {
  /* animation: fadeIn 0.8s; */
}

/* PreNav */
.preNavContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
  height: 25px;
  /* animation: fadeIn 0.6s forwards; */
  /* animation-delay: 0.5s; */
  /* opacity: 0; */
  /* background-color: var(--background-hover-color); */
}
.preNavContent {
  max-width: calc(var(--max-width) + 20px);
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding: 0 10px;
}
.preNavIcon {
  font-size: 16px;
  transition: 0.2s ease-in-out;
}
.preNavText,
.preNavTextStatic {
  display: inline-block;
  position: relative;
  margin-left: 3px;
  font-weight: 600;
  font-size: 14px;
  transition: 0.2s ease-in-out;
}

.preNavText::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-hover);
  transform-origin: bottom left;
  transition: transform 0.2s ease-out;
}
.preNavItemContainerStatic {
  display: flex;
  align-items: center;
  color: var(--grey);
  font-size: 16px !important;
  cursor: default;
  padding-bottom: 2px;
}
.preNavItemContainer {
  display: flex;
  align-items: center;
  color: var(--grey);
  font-size: 14px;
  cursor: pointer;
  margin-left: 15px;
  padding-bottom: 2px;
  transition: 0.2s;
}
.preNavItemContainer:hover .preNavText::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.preNavItemContainer:hover .preNavText {
  color: var(--grey-hover);
}

.preNavItemContainer:hover .preNavIcon {
  color: var(--grey-hover);
}
/* Nav */
.navbarContent {
  justify-content: space-between;
  max-width: calc(var(--max-width) + 20px);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.navbarContainer {
  top: 0px;
  position: sticky;
  /* animation: fadeInDown 0.8s; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 8rem;
  margin-bottom: -8rem;
  align-items: center;
  background-color: var(--main-bg-color);
  border-bottom: 0.5rem solid var(--primary);
  transition: all 0.5s;
  z-index: 2;
  /* box-shadow: 0 1rem 2rem rgba(68, 68, 68, 0.2); */
  box-shadow: 0 6px 8px 0 rgba(68, 68, 68, 0.2),
    0 -1px 10px 0 rgba(68, 68, 68, 0.05);
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 765px) {
  .navbarContainer,
  .navPlaceholder {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .welcomeMessage {
    display: none;
  }
}

.logo {
  width: 180px;
  height: auto;
  margin-top: 5px;
}

.cart {
  font-size: 28px;
  color: var(--primary);
}

.cartContainer {
  transition: 0.2s;
  cursor: pointer;
  padding-right: 5px;
  position: relative;
  margin-left: 50px;
}
.cartContainer:hover {
  transform: scale(1.05);
  transform-origin: center;
}

.cartCount {
  position: absolute;
  top: -6px;
  right: -1px;
  background-color: var(--primary-dark);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
}

.navItem {
  margin-left: 50px;
  height: 8rem;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.noWrap {
  white-space: nowrap;
}
.searchCartContainer {
  width: 100%;
  justify-content: flex-end;
  margin-left: 50px;
}
.navItemText {
  text-transform: uppercase;
  position: relative;
  font-weight: 600;
  color: var(--grey);
  font-size: 20px;
  padding: 5px 0;
  cursor: pointer;
  transition: 0.2s;
  z-index: 1;
}
.navItem:hover .navItemText::after {
  transform: scaleX(0.8);
  transform-origin: bottom left;
}
.navItemText:hover {
  color: var(--grey-hover);
}
.navItemText::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-hover);
  transform-origin: bottom left;
  transition: transform 0.2s ease-out;
}
.dropdownOpenContainer {
  height: 100%;
  display: flex;
  align-items: center;
}
.dropdownOpenContainer:hover .dropdown {
  transition: 0.2s;
  visibility: visible;
  opacity: 1;
  -prefixes-transition-delay: 0s, 0s;
}

.dropdown {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
  -prefixes-transition-property: opacity, visibility;
  -prefixes-transition-duration: 0.4s, 0s;
  -prefixes-transition-delay: 0s, 0.4s;
  top: 8rem;
  left: 0;
  width: 100%;
  background-color: var(--main-bg-color);
  z-index: 2;
  height: fit-content;
  box-shadow: var(--box-shadow-dark);
}
.dropdownHidden {
  height: 0px;
  transition: growUp 0.8s forwards;
  position: absolute;
  top: 8rem;
  left: 0;
  width: 100%;
  background-color: var(--main-bg-color);
  z-index: 0;
  overflow: hidden;
}
.dropdownDisplayNone {
  height: 0px;
  position: absolute;
  top: 8rem;
  left: 0;
  width: 100%;
  background-color: var(--main-bg-color);
  z-index: 0;
  overflow: hidden;
}

.dropdownContainer {
  display: flex;
  justify-content: center;
}

.dropdownContent {
  max-width: calc(var(--max-width) + 20px);
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.dropdownTitle {
  font-weight: 700;
  font-size: 20px;
  color: var(--primary-dark);
  transition: 0.2s;
  margin-bottom: 7px;
}
.dropdownTitle:hover {
  color: var(--primary-dark-hover);
}

.dropdownLink {
  font-size: 17px;
  color: var(--primary);
  margin: 3px 0;
  font-weight: 600;
  transition: 0.2s;
}
.dropdownLink:hover {
  color: var(--primary-hover);
  cursor: pointer;
}
.dropdownSectionContainer {
  padding: 10px 0;
}
/* Animations */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes growDown {
  0% {
    height: 0px;
  }
  100% {
    height: var(--dropdown-height);
  }
}
@keyframes growUp {
  0% {
    height: var(--dropdown-height);
  }
  100% {
    height: 0px;
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
