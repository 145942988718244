.noItemsContainer {
  width: 100%;
  height: 50%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyCart {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  margin: 25px 0;
  color: var(--primary-dark);
}

.grid {
  margin: 25px 0;
}

.image {
  width: 80px;
  height: auto;
}
.itemPrice {
  display: none !important;
}

@media only screen and (min-width: 950px) {
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
  }
  .image {
    width: 150px;
    height: auto;
  }
  .itemPrice {
    display: flex !important;
  }
  .orderSummaryContainer {
    margin: 0;
  }
  .quantityMobile {
    display: none !important;
  }
  .removeButtonContainer {
    position: absolute;
    right: 0px;
    bottom: 0px !important;
  }
  .itemName {
    width: 100% !important;
  }
  .infoRowMobile {
    display: none !important;
  }
}

.infoRowMobile {
  display: flex;
  justify-content: space-between;
}

.quantityMobile {
  display: flex;
  align-items: center;
}

.orderSummaryContainer {
  margin: 25px 0 15px 0;
}

.cartItemsContainer {
  width: 100%;
}

.title {
  font-size: 30px;
  font-weight: 700;
  color: var(--primary-dark);
}
.row {
  display: flex;
}
.rowAligned {
  display: flex;
  align-items: center;
}
.infoRow {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.infoItem {
  color: var(--primary);
  font-weight: 600;
}
.infoItemLarge {
  color: var(--primary-dark);
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}

.cartCount {
  margin-left: 5px;
  font-size: 26px;
  font-weight: 500;
  color: var(--primary);
}
.orderSummaryContainer {
  background-color: var(--secondary-bg-color);
  padding: 15px;
  border-radius: 10px;
  height: fit-content;
}

.cartItem {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}
.removeButtonContainer {
  position: absolute;
  right: 0px;
  bottom: 55px;
}
.cartItemInfo {
  margin-left: 10px;
  width: calc(100% - 85px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.itemName {
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-darker);
  transition: 0.2s;
  width: calc(100% - 100px);
}
.itemName:hover {
  color: var(--primary-dark);
}
.cartItemSubheading {
  color: var(--primary-darker);
  font-size: 18px;
  text-align: right;
  white-space: nowrap;
}
.cartItemSubheadingSmall {
  color: var(--primary-darker);
  font-size: 16px;
  margin-right: 5px;
}
.cartItemSubheadingContent {
  margin-top: 8px;
  font-size: 20px;
  color: var(--primary-dark);
}

.columnInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 30px;
}

