.col-half {
  max-width: 50%;
  width: 100%;
  margin: 3rem;
  height: 100%;
}

.col-large {
  max-width: 65%;
  width: 100%;
  margin: 3rem;
  height: 100%;
}
.col-small {
  max-width: 35%;
  width: 100%;
  margin: 3rem;
  height: 100%;
}

.row-small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 65%;
  margin-top: 1rem;
}
.image-large {
  max-width: 70%;
  width: 100%;
  box-shadow: 0 0 5rem rgba(68, 68, 68, 0.5);
}
.select {
  width: 10rem;
}
.select-small {
  width: 5rem;
}
.bold {
  font-weight: bold;
}
.row-right {
  display: flex;
  flex-direction: row;
}
.center {
  display: flex;
  justify-content: center;
}
.title {
  font-size: 3rem;
  font-weight: bold;
}
.subheading {
  font-size: 1.7rem;
  font-weight: bold;
  text-decoration: underline;
}
.description {
  margin: 1rem 0;
  max-width: 65%;
}
.share-link {
  color: var(--primary);
  font-size: 2.7rem;
  margin-left: 1rem;
  transition: 0.2s;
}
.share-link:hover {
  color: var(--primary-hover);
}

.info-text {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  .col-half,
  .col-large {
    max-width: 100%;
    width: auto;
    margin: 2rem 1rem 1rem 1rem;
    max-height: auto;
  }
  .col-small {
    max-width: 100%;
    width: auto;
    margin: -2rem 1rem 1rem 1rem;
    max-height: auto;
  }
  .row-right {
    flex-direction: column;
  }
  .image-large {
    max-width: 90%;
    width: 100%;
    height: 100%;
    max-height: 90rem;
  }
  .title {
    font-size: 2.4rem;
    font-weight: bold;
  }
  .borders {
    margin-right: 8%;
    margin-left: 8%;
  }
  .description {
    max-width: 100%;
  }
  .row-small {
    max-width: 100%;
  }
}
