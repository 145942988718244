.image-round {
  max-width: 6rem;
  width: 100%;
  border-radius: 0.5rem;
  max-height: 6rem;
  height: 6rem;
  object-fit: cover;
box-shadow: 0 0 5rem rgba(68, 68, 68, 0.2);

}
.product-title-div {
  width: 15rem;
}


.material-size {
  display: flex;
  flex-direction: row;
}
.link-bold {
  font-weight: bold;
}
.checkout-info {
    width: 80%;
    margin: 3rem;
}

.checkout-label {
    padding: 1rem 0;
    margin: auto 1rem;
}
.pay-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.price-type {
    font-weight: bold;
}



@media only screen and (max-width: 767px) {
  .product-title-div {
    width: 10rem;
  }
  .cart-item-info {
    display: inline;
  }
  .select-mobile {
    width: 5rem;
  }
  .checkout-info {
      width: 100%;
      max-width: 100%;
      margin: 0;
  }
  
}
