.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.2s;
  color: var(--primary-dark);
}

.container input:disabled ~.checkmark, .container:hover input:disabled ~.checkmark {
  cursor: not-allowed;
  background-color: var(--search-background-alt);
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  /* cursor: pointer; */
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: var(--search-background-alt);
  transition: 0.2s;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: var(--search-background-alt-hover);
}

.container input:checked ~ .checkmark {
  background-color: var(--primary);
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:focus ~ .checkmark {
  outline: 1px solid var(--primary);
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}