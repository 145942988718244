.cartIcon {
  font-size: 20px;
  color: var(--primary);
  cursor: pointer;
}
.downIcon {
  color: var(--muted-text-color);
  margin-left: 5px;
  font-size: 18px;
  cursor: pointer;
}
.editLink {
  color: var(--primary);
  transition: 0.2s;
  font-size: 16px;
}
.editLink:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

.price {
  color: var(--primary-dark);
  font-weight: 600;
  cursor: default;
}

.itemImage {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cartRow {
  display: flex;
  width: 100%;
  overflow: hidden;
  margin: 15px 0;
}
.cartItemInfo {
  width: 100%;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.productName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  color: var(--primary-dark);
  font-size: 18px;
  font-weight: 600;
  transition: 0.2s;
}

.productName:hover {
  color: var(--primary-dark-hover);
}
.quantity {
    font-size: 15px;
    color: var(--primary)
}

.price {
    color: var(--primary-dark);
    font-weight: 600;
    font-size: 20px;
}

.priceSmall {
  color: var(--primary-dark);
    font-weight: 600;
    font-size: 18px;
    user-select: none;
}