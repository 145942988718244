.navbarContent {
  max-width: calc(var(--max-width) + 20px);
  width: 100%;
  position: relative;

  padding: 10px;
  box-sizing: border-box;
}

.navbarRow {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.navbarContainer {
  top: 0px;
  position: sticky;
  /* animation: fadeInDown 0.8s; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 85px;
  /* height: 8rem; */
  margin-bottom: -85px;
  align-items: center;
  background-color: var(--main-bg-color);
  border-bottom: 0.5rem solid var(--primary);
  transition: all 0.5s;
  z-index: 2;
  /* box-shadow: 0 1rem 5rem rgba(68, 68, 68, 0.1); */
  box-shadow: 0 6px 8px 0 rgba(68, 68, 68, 0.2), 0 -1px 10px 0 rgba(68, 68, 68, 0.05);

  display: none;
}
.hide {
    display: none;
}
.topContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 180px;
  height: auto;
  margin-top: 5px;
}

.logoSmallNav {
  display: none;
  width: auto;
  height: 60px;
}
@media only screen and (max-width: 430px) {
  .logoSmallNav {
    display: inline;
  }
  .logo {
    display: none;
  }
}

.menu {
  font-size: 28px;
  color: var(--primary);
  cursor: pointer;
  transition: 0.2s;
}

.cartContainer {
  transition: 0.2s;
  cursor: pointer;
  padding-right: 5px;
  position: relative;
}

.menu:active,
.cartContainer:active,
.search:active {
  color: var(--primary-hover);
  transform: scale(1.1);
  transform-origin: center;
  background: none;
}
.cart {
  font-size: 26px;
  color: var(--primary);
  transition: 0.2s;
}
.cartContainer:active .cart {
  color: var(--primary-hover);
  background: none;
}

.search {
  font-size: 24px;
  color: var(--primary);
  cursor: pointer;
  transition: 0.2s;
}

.sideContainer {
  width: 90px;
  display: flex;
  justify-content: space-between;
}
.cartSearchRow {
  display: flex;
}

.navPlaceholder {
  display: none;
}
@media only screen and (max-width: 765px) {
  .navbarContainer {
    display: block;
  }
  .navPlaceholder {
    display: inline;
  }
}

.drawer {
  width: 100vw;
  height: 100vh;
  background-color: var(--main-bg-color);
  z-index: 5;
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transform: translateX(-150vw);
  transition: all 0.4s;
}

.drawer.drawerOpen {
  transform: translateX(0);
}

.drawerContainerTopRow {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-hover);
}
.drawerRow {
  height: 70px;
}
.drawerLink {
  border-bottom: 1px solid var(--primary-hover);
  font-size: 26px;
  font-weight: 500;
  padding: 0 20px;
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
}
.drawerLink:active {
  background-color: var(--secondary-bg-color-hover);
  cursor: pointer;
}
.drawerLinkText {
  color: var(--primary);
}
.drawerLogo {
  width: auto;
  height: 55px;
}

.linkContainer {
  flex: 1;
  overflow: auto;
}

.closeIcon {
  font-size: 34px;
  color: var(--primary);
  transition: 0.2s;
  cursor: pointer;
}
.closeIcon:hover {
  color: var(--primary-hover);
  transform: scale(1.1);
}

.navSearch {
  position: absolute;
  width: 100%;
  top: 78px;
  left: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
  border-bottom: 0.5rem solid var(--primary);
  background-color: var(--main-bg-color);
  box-shadow: 0 6px 8px 0 rgba(68, 68, 68, 0.2);
}
.searchShow {
  animation: fadeInDown 0.4s forwards;
}

.searchHide {
  animation: fadeUpOut 0.4s forwards;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeUpOut {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
  66% {
    opacity: 1;
    transform: translateY(-8px);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
}