.container,
.containerExpanded {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--search-background);
  border-radius: 5px;
  padding: 0 5px;
  border: 1px solid transparent;
  transition: 0.2s;
  cursor: pointer;
  /* width: 100%; */
}
.container {
  width: 160px;
}

.containerExpanded {
  width: min(100%, 300px);
}

.containerMobile {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--search-background);
  border-radius: 5px;
  padding: 0 5px;
  border: 1px solid transparent;
  transition: 0.2s;
  cursor: pointer;
  width: 100%;
}

.container:focus-within,
.containerExpanded:focus-within,
.containerMobile:focus-within {
  border: 1px solid var(--primary-hover);
}

.searchIcon {
  padding-left: 3px;
  color: var(--primary-hover);
}

.search {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  transition: 0.2s;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--primary);
}
.search::placeholder {
  color: var(--primary);
}
.search:hover {
  outline: none;
  border: none;
}
.label {
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}

.searchContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* dropdown styles */
.dropdown {
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  height: auto;
  background-color: var(--main-bg-color);
  border-radius: 0 0 10px 10px;
  box-shadow: var(--box-shadow);
}

.dropdownContainer {
  border-radius: 0 0 10px 10px;
  padding: 10px;
}
.center {
  display: flex;
  justify-content: center;
}

.dropdownSubheading {
  color: var(--primary-dark);
  font-size: 16px;
  font-weight: 600;
}

.dropdownLink {
  color: var(--primary);
  transition: 0.2s;
  padding: 5px;
  font-size: 17px;
}

.dropdownLink:hover {
  color: var(--primary-hover);
}

.noData {
  text-align: center;
  color: var(--primary-hover);
  font-size: 16px;
}
