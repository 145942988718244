footer {
  background-color: var(--secondary-bg-color);
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  z-index: 1;
}

.title {
  font-size: 19px;
  color: var(--primary-dark);
  font-weight: 600;
}

.tabItem {
  font-size: 18px;
  color: var(--primary);
  margin: 10px 0;
  cursor: pointer;
  transition: 0.2s;
}

.tabItem:hover {
    color: var(--primary-hover)
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.linkIcon {
    color: var(--primary);
    font-size: 18px;
    margin: 0 4px;
    transition: 0.2s;
}
.linkIcon:hover {
    color: var(--primary-hover)
}

.linkIcon:first-child {
    margin-left: 0;
}
.linkIcon:last-child {
    margin-right: 0;
}

.logo {
  width: 200px;
  max-width: 80%;
  height: auto;
}
.copyrightLogo {
  font-size: 13px;
}
.rowCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.rowSpace {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--primary);
  align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.logoSmall {
    height: 28px;
    width: auto;
    object-fit: contain;
}

.content {
  margin-top: 20px;
}

@media only screen and (min-width: 500px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
