.button {
    padding: 5px 10px;
    color: var(--button-text-color);
    transition: 0.2s;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    font-size: 16px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonSmall {
    padding: 5px 10px;
    color: var(--button-text-color);
    transition: 0.2s;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    font-size: 14px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:disabled, .buttonSmall:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}



.success {
    background-color: var(--success-color);
}
.success:hover {
    background-color: var(--success-color-hover);
}

.alert {
    background-color: var(--delete-color);
}

.alert:hover {
    background-color: var(--delete-color-hover);
}
.noBorder {
    background-color: transparent;
    color: var(--primary);
}

.noBorder:hover {
    color: var(--primary-hover);
}

.onlyBorder {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
}
.onlyBorder:hover {
    color: var(--primary-hover);
    border: 1px solid var(--primary-hover);
}

.general {
    background-color: var(--primary);
}

.general:hover {
    background-color: var(--primary-hover);
}

.general:hover:disabled {
    background-color: var(--primary);
    cursor: not-allowed;
}

.button:hover, .buttonSmall:hover {
    cursor: pointer;
}