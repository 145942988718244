@import "./css/header.css";
@import "./css/product-screen.css";
@import "./css/cart.css";
@import "./css/order.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  /* background-color: var(--background-color); */
}
body {
  height: 100vh;
  margin: 0;
  font-size: 1.6rem;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:root {
  --max-width: 1280px;
  --main-bg-color: #fff;
  --background-color: rgb(252, 252, 252);
  --primary: #F16681;
  --primary-hover: #e78497;
  --primary-dark: #4d4d4d;
  --primary-dark-hover: #313131;
  --primary-darker: #303030;
  --primary-hover-dark: #4c4c4c;
  --primary-light: #F16681;
  --primary-muted: #f1668274;
  --secondary: #f6a0a4;
  --secondary-hover: #d2878b;
  --muted-text-color: #a3a3a3;
  --muted-text-color-hover: #c4c4c4;
  --muted-text-dark: #696969;
  --main-text-color: #333333;
  --light-text: #adadad;
  --secondary-bg-color: rgb(250, 250, 250);
  --secondary-bg-color-hover: rgb(243, 236, 236);

  --grey: #58545A;
  --grey-hover: #727272;

  --search-background: #6166530f;
  --search-background-light: #f1e3e6;
  --search-background-alt: #f1e3e6;
  --search-background-alt-hover: #f2dadf;
  --input-outline-color: rgb(190, 190, 190);
  --background-hover-color: #fff6f8;
  --unselected-tab: #e9a8b6;

  --button-text-color: #fff;
  --delete-color: rgb(214, 50, 50);
  --delete-color-hover: rgb(165, 26, 26);
  --delete-background: #f5eded;
  --edit-color: rgb(124, 124, 124);
  --edit-color-hover: rgb(161, 161, 161);
  --success-color: rgb(0, 209, 28);
  --success-color-hover: rgb(35, 180, 22);

  --card-outline-color: #e6e6e6;
  --card-outline-color-hover: #dadada;
  --card-secondary-color: #fdfdfd;
  --box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --box-shadow-light: rgba(0, 0, 0, 0.05) 0px 4px 12px;
  --box-shadow-dark: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --box-shadow-arrow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  --input-box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  --input-focus-box-shadow: 0 0 0 3px hsla(210, 96%, 45%, 25%),
    0 1px 1px 0 rgba(0, 0, 0, 0.08);
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.content-container {
  max-width: calc(var(--max-width) + 20px);
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.content-container-full {
  max-width: var(--max-width);
  padding: 10px 0px;
  width: 100%;
  margin-bottom: 10px;
}

.row-container {
  max-width: var(--max-width);
  width: 100%;
}

.image-gallery-image {
  height: 50vh;
  max-height: 500px !important;
  width: 100%;
  object-fit: cover !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

main {
  display: flex;
  flex: 1;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--search-background);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--primary);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.loaderLarge {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.py-2 {
  padding: 10px 0;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.brands-grid {
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  display: grid;
  grid-gap: 16px;
}
.product-grid,
.product-grid-small {
  display: block;
  display: grid;
  grid-gap: 16px;
  justify-items: center;
}
.filter-container {
  display: none;
}

.mobile-filter-container {
  display: block;
}
@media (min-width: 568px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 960px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 700px) {
  .product-grid-small {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-container {
    display: block;
  }
  .mobile-filter-container {
    display: none;
  }
}

@media (min-width: 860px) {
  .product-grid-small {
    grid-template-columns: repeat(3, 1fr);
  }
}

.logo-unauthorized {
  max-width: 500px;
  width: 80%;
  height: auto;
}

.text-unauthorized {
  margin: 10px 0;
  font-size: 24px;
  color: var(--secondary);
}

.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-col {
  width: 100%;
}

/* Layout */
.grid-container {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo-image {
  margin-left: 1rem;
}

hr {
  height: 0.1rem;
  width: 100%;
  background-color: var(--primary-hover);
  border-radius: 25%;
  border: var(--primary-hover);
}
/* Common */
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
}
a {
  text-decoration: none;
  color: #333333;
}

.link-underlined {
  text-decoration: underline;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li {
  margin-top: 1rem;
}
/* input,
select,
textarea,
button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.select {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* button {
  background-color: #f8f8f8;
  cursor: pointer;
} */
/* input:hover,
select:hover,
textarea:hover,
button:hover,
.select:hover {
  border: 0.1rem #404040 solid;
} */
button.primary {
  background-color: #333333;
  color: white;
}
button.block {
  width: 100%;
}
button.small {
  font-size: 1.2rem;
}
/* Header */

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.col {
  width: 33.33%;
  display: flex;
  justify-content: center;
  min-width: 31rem;
}

.col-1 {
  flex: 1 1 25rem;
}
.col-2 {
  flex: 2 1 50rem;
}
.col-3 {
  flex: 3 1 75rem;
}
.min-30 {
  min-width: 30rem;
}
.p-1 {
  padding: 1rem;
}
/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(-30rem);
  transition: all 0.5s;
}
aside.open {
  transform: translateX(0);
}
button.open-sidebar {
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  background: none;
  color: #ffffff;
  cursor: pointer;
}
button.open-sidebar:hover {
  border-color: #ffffff;
}
aside ul {
  padding: 0;
  list-style: none;
}
aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

button.close-sidebar {
  padding: 0.3rem 0.8rem;
}
/* Images */
.image-border {
  border-radius: 0.5rem;
}
.image-shadow {
  box-shadow: 0 0 5rem rgba(68, 68, 68, 0.5);
}
img.small {
  max-width: 5rem;
  width: 100%;
}

img.medium {
  max-width: 29rem;
  width: 100%;
  object-fit: scale-down;
}
img.large {
  width: 50%;
}
/* Card */
.card {
  /* border: 0.1rem #c0c0c0 solid; */
  /* background-color: #F5F5F5; */
  border-radius: 0.5rem;
  margin: 0 3rem 3rem 3rem;
  max-width: 31rem;
}
.card img.medium {
  transition: transform 250ms ease-out;
}
.card-image {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 29rem;
}
.card:hover img.medium {
  transform: scale(1.05);
  transform-origin: center;
}
.card-body {
  padding: 1rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 2rem;
}
/* Rating */
.rating span {
  color: var(--primary-hover);
  margin: 0.1rem;
}
.rating .reviews {
  color: #404040;
}
/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
:disabled {
  opacity: 70%;
}
/* Form */
.form {
  max-width: 60rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
/* .form label {
  margin: 1rem 0;
} */
/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.link-dropdown {
  padding-bottom: 2.5rem;
}
.row-small-review {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.row-small-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 58rem;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.dropdown-content {
  position: absolute;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  min-width: 13.5rem;
  padding: 1rem;
  z-index: 1;
  background-color: #fff;
  border: 0.1rem solid #ebebeb;
  margin: 0;
  margin-right: 1rem;
  margin-top: 2.4rem;
  border-radius: 0 0 0.5rem 0.5rem;
  transition: 0.2s;
  font-weight: 500;
  -prefixes-transition-property: opacity, visibility;
  -prefixes-transition-duration: 0.4s, 0s;
  -prefixes-transition-delay: 0s, 0.4s;
}
.dropdown-content-small {
  position: absolute;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  min-width: 8rem;
  padding: 1rem;
  z-index: 1;
  background-color: #fff;
  border: 0.1rem solid #ebebeb;
  margin: 0;
  margin-right: 1rem;
  margin-top: 2.4rem;
  border-radius: 0 0 0.5rem 0.5rem;
  transition: 0.2s;
  font-weight: 500;
  -prefixes-transition-property: opacity, visibility;
  -prefixes-transition-duration: 0.4s, 0s;
  -prefixes-transition-delay: 0s, 0.4s;
}

.dropdown-links {
  color: #333333;
}
.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content-small {
  /* display: block; */
  transition: 0.2s;
  visibility: visible;
  opacity: 1;
  -prefixes-transition-delay: 0s, 0s;
}
/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}

/* Carousel */
.carousel .thumbs {
  text-align: center;
  white-space: normal !important;
  transform: none !important;
}
.carousel.carousel-slider {
  overflow: visible !important;
}
.carousel .control-dots {
  bottom: -40px !important;
}
.carousel .control-dots .dot {
  background-color: var(--primary) !important;
  width: 40px !important;
  height: 5px !important;
  border-radius: 3px !important;
  box-shadow: var(--box-shadow-dark) !important;
}
.carousel .thumbs-wrapper {
  margin: 20px !important;
  padding: 20px;
}
.carousel .thumb.selected,
.carousel .thumb.selected:hover {
  border: 1px solid var(--primary) !important;
  border-radius: 8px !important;
  cursor: default;
  box-shadow: var(--box-shadow);
}
.carousel .thumb:hover {
  border: 1px solid var(--primary-hover) !important;
  border-radius: 8px !important;
  cursor: pointer;
}

.carousel .thumb {
  border: 1px solid transparent !important;
  border-radius: 8px !important;
  margin: 3px !important;
}

@media only screen and (max-width: 800px) {
  .carousel .thumb {
    width: 60px !important;
  }
}

/* Search */
.search button {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right: none;
  margin-right: 0.5rem;
}
.search input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none;
  margin-left: 0.5rem;
}
.active {
  font-weight: bold;
}
.scroll-row {
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  max-width: 100%;
}

.scroll-row::-webkit-scrollbar {
  display: none;
}

.scroll-row-item-container {
  width: 100%;
  max-width: 25%;
  min-width: 25%;
}

.scroll-row-item {
  box-sizing: border-box;
  border: 0.1rem solid grey;
  padding: 2rem;
  border-radius: 2rem;
  margin: 1rem;
  height: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.reviews-row {
  margin: 2rem;
}

@media only screen and (max-width: 767px) {
  .col {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .scroll-row-item-container {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
