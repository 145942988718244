.grid {
  margin: 10px 0;
  display: grid;
  grid-template-columns: 60px 1fr 50px;
  grid-gap: 15px;
  border-radius: 10px;
  padding: 15px;
  transition: 0.2s;
}
.grid:hover {
  background-color: var(--search-background)
}
.grid:first-child {
  margin-top: 0;
}
.grid:last-child {
  margin-top: 0;
}
@media only screen and (min-width: 950px) {
.grid {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 100px 1fr 70px;
  grid-gap: 20px;
}
.name, .price {
    font-size: 22px !important;
}
.qty {
  font-size: 18px;
}
.date {
  font-size: 17px;
}
}

.image {
  width: 100%;
  height: auto;
}

.name {
  font-size: 14px;
  color: var(--primary-darker);
  transition: 0.2s;
  width: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.date {
  font-size: 15px;
  color: var(--primary);
}

.qty {
    color: var(--primary);
    font-size: 14px;
    margin-top: 5px;
}

.price {
    color: var(--primary-dark);
    font-size: 15px;
    text-align: right;
}