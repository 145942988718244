.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
}
.container:hover {
    background-color: var(--secondary-bg-color);
}
.image {
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.name {
    color: var(--primary);
    font-size: 22px;
    
}