.link {
  color: var(--primary-hover);
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  transition: 0.2s;
}
.link:hover {
  color: var(--primary)
}
.linkContainer {
  margin: 0 7px;
}
.linkContainer:first-child {
  margin-left: 0;
}
.linkActive {
  color: var(--primary-dark);
  margin: 0 7px;
  font-weight: 500;
  font-size: 18px;
}

.container {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
}
.slash {
  color: var(--primary-hover);
}
