.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 10px 0;
  box-sizing: border-box;
}

.scrollContainer {
  display: flex;
  overflow-x: auto;
  width: 100%;
  max-width: 100vw;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}

.cardContainer {
  width: 100%;
  min-width: 200px;
  max-width: 200px;
  margin: 0 10px;
}
.cardContainer:first-child {
  margin-left: 0;
}
.cardContainer:last-child {
  margin-right: 0;
}

.leftArrowContainer {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.747), rgba(255, 255, 255, 0));
  transition: opacity 0.3s;
}
.arrowCircle {
  margin-top: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: var(--box-shadow-arrow);
  transition: 0.2s;
  cursor: pointer;
}

.rightArrowContainer {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0.747), rgba(255, 255, 255, 0));
  transition: opacity 0.3s;
}

.arrowCircle:hover {
  transform: scale(1.1);
}

.arrow {
  font-size: 20px;
  color: var(--primary-dark);
}
