.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.order-contain {
    max-width: 55rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.heading-2 {
    font-size: 1.7rem;
    font-weight: bold;
    margin: 2rem 0 1rem 0;
    text-decoration: underline;
}

.icon-delete {
    color: #333333;
    cursor: pointer;
}
.icon-delete:hover {
    color: grey;
}