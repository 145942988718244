.mobileFilterContainer {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-bg-color);
  z-index: 5;
  position: fixed;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  transform: translateY(200rem);
  transition: all 0.4s;
}

.mobileFilterContainer.mobileFilterOpen {
  transform: translateY(0rem);
}