.modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* position: fixed; */
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorMessageContainer {
  height: 10px;
  color: var(--delete-color);
  text-align: center;
}

.show {
  animation: fadeIn 0.5s forwards;
}
.showModal {
  animation: fadeInDown 0.5s forwards;
}

.hide {
  animation: fadeOut 0.5s forwards;
}
.hideModal {
  animation: fadeOutUp 0.5s forwards;
}
.displayNone {
  display: none;
}

.container {
  width: 90%;
  max-width: 600px;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: var(--main-bg-color);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
}
.containerLarge {
  width: 90%;
  max-width: 800px;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: var(--main-bg-color);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  box-shadow: var(--box-shadow);
}
.content {
  padding: 25px;
}
.modal {
  background-color: rgba(0, 0, 0, 0.3);
}

.headerNoTitle {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.errorMessageContainer {
  height: 30px;
  color: var(--delete-color);
  text-align: center;
}

.title {
  color: var(--primary);
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}
.titleSmall {
  color: var(--primary);
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
.subheading {
  color: var(--primary-hover);
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  box-sizing: border-box;
}
.paddingY {
  padding: 10px 0;
}
.paddingWithHelperText {
  padding-top: 10px;
}
.rowCenter {
  display: flex;
  justify-content: center;
}

.linkRow {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  color: var(--primary-darker);
}

.link {
  margin-left: 3px;
  color: var(--primary);
  transition: 0.2s;
  cursor: pointer;
}

.link:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.helperText {
  margin-left: 5px;
  font-size: 13px;
  color: var(--primary);
  user-select: none;
}
.successMessage {
  padding-top: 10px;
  color: var(--primary);
  text-align: center;
}

.text {
  color: var(--primary-darker);
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
