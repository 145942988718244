.image {
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.name {
  font-size: 14px;
  width: 80%;
  color: var(--primary-dark);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.nameContainer {
    height: 38px;
}
.container {
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-radius: 8px;
  transition: 0.2s;
  background-color: var(--secondary-bg-color);
  border: 2px solid var(--secondary-bg-color);
}

.container:hover {
  box-shadow: var(--box-shadow-light);
}
