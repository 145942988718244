.grid,
.gridReverse,
.gridQuad {
  margin: 25px 0;
}

@media only screen and (min-width: 950px) {
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
  }
  .gridReverse {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 40px;
  }
  .secondaryContainer {
    grid-column: 2;
    grid-row: 1;
  }
  .mainContainer {
    grid-column: 1;
    grid-row: 1;
  }
  .gridQuad {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
}
.backgroundContainer {
  padding: 15px;
  /* background-color: var(--search-background); */
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  margin: 20px 0;
}

.containerNoBackground {
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  /* box-shadow: var(--box-shadow); */
}

.card {
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 100%;
  background-color: var(--secondary-bg-color);
  /* box-shadow: var(--box-shadow); */
  padding: 20px 10px;
  border-radius: 8px;
  margin: 10px 0;
}
.cardWithHover {
  transition: 0.2s;
  cursor: pointer;
}
.cardWithHover:hover {
  transform: scale(1.03);
  transform-origin: center;
}

.cardCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-dark);
}

.fact {
  background-color: var(--search-background);
  padding: 7px 20px;
  border-radius: 8px;
  color: var(--primary);
  margin: 5px 0;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.2s;
  width: 100%;
}

.fact:hover {
  border: 1px solid var(--primary-light);
  opacity: 0.85;
}

.factClose {
  visibility: hidden;
  position: absolute;
  right: 5px;
  top: 8px;
}
.categoryClose {
  visibility: hidden;
  position: absolute;
  right: 5px;
  top: 6px;
}
.fact:hover .factClose {
  visibility: visible;
}

.categoryItem {
  padding: 5px 20px;
  margin: 3px 5px;
  background-color: var(--search-background);
  border-radius: 8px;
  color: var(--primary-dark);
  cursor: pointer;
  transition: 0.2s;
  position: relative;
}
.categoryItem:hover {
  outline: 1px solid var(--primary);
}

.categoryItem:first-child {
  margin-left: 0;
}

.categoryItemSelected {
  background-color: var(--primary);
  color: var(--button-text-color);
}

.categoryItemSelected:hover {
  opacity: 0.8;
}
.categoryItemSelected:hover .categoryClose {
  visibility: visible;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.addCategory {
  font-size: 16px;
  color: var(--primary);
  transition: 0.2s;
  cursor: pointer;
}
.addCategory:hover {
  color: var(--primary-hover)
}

@media only screen and (min-width: 600px) {
  .card {
    padding: 30px;
  }
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-dark);
}

.titleSmall {
  font-size: 26px;
  font-weight: 600;
  color: var(--primary-dark);
}

.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
  font-size: 18px;
  color: var(--primary-hover);
}
.noDataSmall {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size: 18px;
  color: var(--primary-hover);
}

.label {
  font-size: 14px;
  color: var(--primary);
  font-weight: 600;
  margin-left: 10px;
}
.errorMessage {
  height: 30px;
  color: var(--delete-color);
  text-align: center;
}