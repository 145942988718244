/* Header */
header {
  position: fixed;
  width: 100%;
  height: 7rem;
  background-color: #fff;
  box-shadow: 0 1rem 5rem rgba(68, 68, 68, 0.1);
  z-index: 1000;
}
.link {
  font-weight: bold;
  color: #333333;
  font-size: 1.8rem;
  transition: 0.2s;
}
.link:hover {
    color: #333333;
    transition: 0.2s;
}
.fa-shopping-cart {
  color: #333333;
  font-size: 3rem;
}
.fa-user {
    color: #333333;
    font-size:1.7rem;
    margin-right: 0.7rem;
}
.fa-stack {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  transition: 0.2s;
}
.fa-stack-text {
  color: #fff;
}

.fa-stack-text {
  font-size: 1rem;
  margin-top: -0.45rem;
  margin-left: 0.28rem;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1rem;

}

header a {
  color: white;
  padding: 1rem;
}

@media only screen and (max-width: 767px) {
  .link {
  font-weight: bold;
  color: #333333;
  font-size: 1.5rem;
  transition: 0.2s;
}
  
}
